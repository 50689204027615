import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/meta/athenian-meta.png'
import AllWorkIcon from '../../assets/images/product/blurb-icons/all-work.png'
import GearIcon from '../../assets/images/product/blurb-icons/gear.png'
import LockIcon from '../../assets/images/product/blurb-icons/lock.png'
import FAQ from '../../components/EnterpriseFAQ'
import Layout from '../../components/Layout'
import CTABlock from '../../components/blocks/CTABlock'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import QuoteBlock, { quotes } from '../../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import Blurb from '../../components/system/Blurb'
import { responsiveScale } from '../../styles/helpers'
import QuoteMosaicBlock from '../../components/blocks/QuoteMosaicBlock'
import { DarkLogoBlock } from '../../components/blocks/DarkLogoBlock'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/overview/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    mosaicLeft: file(relativePath: { eq: "images/mosaic/balance.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/mosaic/transparent-surveys.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft2: file(relativePath: { eq: "images/mosaic/data-cloud.png" }) {
      childImageSharp {
        gatsbyImageData(width: 680)
      }
    }
    mosaicRight2: file(relativePath: { eq: "images/mosaic/ci.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    overview: file(relativePath: { eq: "images/enterprise/overview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    integrations: file(
      relativePath: { eq: "images/enterprise/integrations.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    workingAgreement1: file(
      relativePath: { eq: "images/product/overview/target.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    workingAgreement2: file(
      relativePath: { eq: "images/product/overview/slack-notification.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    workingAgreement3: file(
      relativePath: { eq: "images/product/overview/working-agreement.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    organizationalInsights: file(
      relativePath: {
        eq: "images/product/overview/organizational-insights.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    deploymentInsights: file(
      relativePath: { eq: "images/product/overview/deployment-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    pinpointIssues: file(
      relativePath: { eq: "images/product/overview/pinpoint-issues.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`

const BlueOptimaPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Athenian vs. Swarmia"
      variant="dark"
      isNew
      description="Just like Athenian used to, Swarmia uncovers healthy engineering insights that help software organizations continuously improve their ways of working."
      metaImage={MetaImage}
    >
      <HeroBlock
        title={<>Athenian vs. Swarmia</>}
        content="Just like Athenian used to, Swarmia uncovers healthy engineering insights that help software organizations continuously improve their ways of working."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <Box backgroundColor="black" paddingY={40} paddingX={{ xs: 20, md: 36 }}>
        <DarkLogoBlock hideBackground />
      </Box>
      <QuoteMosaicBlock
        title="Get a complete view of your engineering organization"
        content={
          <>
            Swarmia combines metrics from your issue tracker with data from
            developer surveys, giving engineering leaders a comprehensive view
            of their organization.
            <br />
            <br />
            Traditional engineering productivity tools rely solely on issue
            tracker metrics, which can lead to developers gaming the system and
            managers making poor decisions.
          </>
        }
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
        quote={quotes.dave}
      />
      <QuoteMosaicBlock
        title="Make decisions based on&nbsp;real-time&nbsp;data"
        content={
          <>
            A recurring piece of feedback we hear from companies using other
            engineering productivity software is that the data is often
            outdated, and therefore difficult to action.
            <br />
            <br />
            With Swarmia, all data is updated in real time, which means you’re
            able to make important decisions based on the latest information.
          </>
        }
        image={getImage(data.mosaicLeft)!}
        quote={quotes.alex}
      />
      <QuoteMosaicBlock
        title="See the first insights in minutes, not weeks"
        content={
          <>
            Typically engineering productivity platforms are unintuitive and
            time-consuming to set up. What’s more, the tools offer no automated
            mechanisms to ensure your data stays in good shape after the initial
            onboarding.
            <br />
            <br />
            Swarmia, on the other hand, is easy to both get started with and
            maintain. All settings are available to admin users to make it easy
            to adjust your setup as your data evolves.
          </>
        }
        image={getImage(data.mosaicRight2)!}
        imageAlign="right"
        quote={quotes.pau}
      />
      <QuoteBlock person="maryanna" />
      <LeadBlock
        heading="Works for your developers, not against them"
        content="Swarmia provides your team with actionable insights and tools helping your organization ship faster."
      />
      <ColumnsBlock
        paddingTop={responsiveScale(48)}
        paddingBottom={responsiveScale(48)}
        columns={[
          <Blurb
            key="1"
            title="Built to scale"
            text="From team hierarchies to role-based access and export APIs, Swarmia fits the needs of large organizations."
            isLarge
            image={getImage(data.overview)!}
          />,
          <Blurb
            key="2"
            title="Easy to integrate"
            text="Swarmia works with your internal systems as well as the tools your engineering teams already use."
            isLarge
            image={getImage(data.integrations)!}
          />,
        ]}
      />
      <ColumnsBlock
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            icon={GearIcon}
            title="Flexible set up and configuration"
            text="Setting up Swarmia is easy. You can either do it yourself or get support from our customer success team — at no extra cost."
          />,
          <Blurb
            key="2"
            icon={AllWorkIcon}
            title="Roll out at your own pace"
            text="Your dedicated customer success manager will work with your team to tailor onboarding and rollout."
          />,
          <Blurb
            key="3"
            icon={LockIcon}
            title="Designed security-first"
            text="Swarmia is SOC 2 Type 2 and GDPR compliant. We perform thorough security audits twice a year."
          />,
        ]}
      />
      <LeadBlock
        heading="Metrics alone don’t drive change"
        content="Adopt working agreements to stay accountable for things that are important to your team."
        link={{
          title: 'Read more about working agreements',
          href: '/product/working-agreements/',
        }}
      />
      <ColumnsBlock
        paddingTop={responsiveScale(48)}
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            title="Adopt proven ways of working"
            text="Work with your team to set relevant and achievable targets."
            image={getImage(data.workingAgreement1)}
          />,
          <Blurb
            key="2"
            title="Automate feedback loops"
            text="Get real-time alerts in Slack to keep up with your agreements."
            image={getImage(data.workingAgreement2)}
          />,
          <Blurb
            key="3"
            title="Track progress"
            text="Avoid slipping and celebrate progress with frequent recaps."
            image={getImage(data.workingAgreement3)}
          />,
        ]}
      />
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
      >
        <CTABlock title="Get&nbsp;started&nbsp;today." />
        <FAQ />
      </Box>
      <Box paddingBottom={responsiveScale(64)}>
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/developer-survey-questions/',
            '/blog/minimizing-noise-and-bias-in-developer-surveys/',
            '/blog/measuring-software-development-productivity/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default BlueOptimaPage
